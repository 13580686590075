import React from "react"

import Layout from "components/Layout/en"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./AssemblyControl.svg"
import { Table } from "react-bootstrap"
import { MdDone } from "react-icons/md"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        With MoonVision AssemblyControl, you have a tool set for manufacturing
        at your disposal with which individually manufactured components can be
        automatically checked for their composition, positioning and alignment.
        On request, a target/actual comparison with your existing data sources
        can also be carried out. Customers benefit from a stable and reliable
        quality check in their production process and significantly shortened
        lead times.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>
          Automated quality inspection of individually assembled components
        </li>
        <li>Benefit from consistent quality and reduced lead times</li>
        <li>Detection and measurement with a camera & software</li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual Output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="AssemblyControl"
        title="Automated real-time inspection of assembled products"
        subtitle="Automated quality control of assembly product"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated component testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Examination of:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>All material types</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Stationary items</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Moving objects</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Individual product components</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Whole product</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Size</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Position</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            MoonVision AssemblyControl supports you in the inspection of
            processed components. New technologies such as machine learning are
            used to train the components used. Components are tested for their
            positioning, alignment and composition according to their
            specifications. The system can also check whether electronic
            components are correctly connected to each other. After successful
            testing, the information obtained can be used, for example, to
            control processes, issue warning messages or flow into statistics.
          </p>
          <p>
            The artificial intelligence used outshines existing approaches with
            regard to inspection accuracy - the evaluation process is fully
            automated. You profit from the time savings.
          </p>
          <p>MoonVision AssemblyControl helps you to:</p>
          <ul>
            <li>Improve critical throughput times significantly</li>
            <li>Inspect inaccessible test locations</li>
            <li>Achieve high detection rates</li>
            <li>To achieve a constant quality despite a multitude of checks</li>
          </ul>
          <p>
            MoonVision AssemblyControl can be used at different production steps
            from the incoming goods inspection to the outgoing goods inspection.
            Due to its self-sufficient design, the software can be integrated
            into a running production process or existing ERP systems.
          </p>
          <p>At the end of the process, results can also be:</p>
          <ul>
            <li>Displayed visually(on a monitor/alarms/storage of jpg files</li>
            <li>Transmitted in tabular form</li>
            <li>Passed on to management information systems via APIs</li>
            <li>Used for process control via APIs</li>
          </ul>
          <p>Thanks to the MoonVision concept</p>
          <ul>
            <li>Individual adaptations can be carried out independently</li>
            <li>You stay in control of access and checkable parameters</li>
          </ul>
          <h3>Available where you need it</h3>
          <p>
            MoonVision AssemblyControl can use all existing camera systems as
            data sources - which meet the Genicam standard. We are also happy to
            advise you on the choice of the right system. The verification can
            be done in real time via our online platform. Alternatively, we can
            also install the system on site. In both cases you get access to the
            MoonVision Toolbox and can make adjustments e.g. for parameter
            adaptation and personalization at any time.
          </p>
          <h3>Simple to use</h3>
          <p>
            Due to the software design, the optimization and implementation of
            MoonVision AssemblyControl can be realized on site with little
            effort. Already after checking a few image/video data there is
            certainty about the quality, further image data can be added at a
            later time. All data is collected and stored centrally and can be
            retrieved by you at any time.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
